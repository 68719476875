<template>
    <div class='abilityDetails'>
        <div class="content">
            <!-- 折线图 -->
            <div class="echart">
                <div id="BrokenLine" class="histogram"></div>
                <div class="timenode">
                    <div v-for="(item, index) in nodeData" :key="index">{{item}}</div>
                </div>
            </div>
            <!-- 时间段选择 -->
            <selectTimePeriod :color="colorData" @changeItem="changeItem"></selectTimePeriod>

            <div class="tipsNum">
                <div class="mini">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最低值</div>
                    </div>
                    <div class="text">
                        <div class="num">59</div>
                        <div>次/分钟</div>
                    </div>
                </div>
                <div class="max">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最高值</div>
                    </div>
                    <div class="text">
                        <div class="num">70</div>
                        <div>次/分钟</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    VisualMapComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import selectTimePeriod from './components/selectTimePeriod.vue'
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
]);

export default {
    components: {
        selectTimePeriod
    },
    data() {
        return {
            current: `${new Date().getHours()}:${new Date().getMinutes()}`,
            nodeData: ['2022-12-07','2022-12-10','2022-12-13'],
            colorData: {
                color: '#EE5B58',
                bagColor: '#FEEAEB'
            },
        };
    },
    props:{
        
    },
    created() {
        
    },
    mounted() {
        this.$nextTick(() => {
            this.initializationBrokenLine();
        })
    },
    methods: {
        // 初始化折线图
        initializationBrokenLine(){
            if(!document.getElementById('BrokenLine')){
                return
            }
            echarts.use([
                TitleComponent,
                TooltipComponent,
                GridComponent,
                VisualMapComponent,
                LineChart,
                CanvasRenderer,
                UniversalTransition
            ]);
            var chartDom = document.getElementById('BrokenLine');
            var myChart = echarts.init(chartDom);
            var option;
            let data = [
                {time: '2022-12-07',value: '100'},
                {time: '2022-12-07',value: '82'},
                {time: '2022-12-07',value: '94'},
                {time: '2022-12-07',value: '132'},
                {time: '2022-12-07',value: '150'},
            ]
            option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        return '<div style="display: flex;align-content: center;">'+ 
                                    '<div style="color: #4F4F4F;font-weight: 700;">'+ params[0].data.value+'</div>'+
                                '</div>'+
                                '<div>'+params[0].data.time+'</div>';
                    }
                },
                xAxis: { 
                    type: 'category',
                    show: false,
                    axisLine:{
                        show:false // 是否显示坐标轴
                    },
                    axisTick: {
                        show: false  //是否显示坐标轴刻度
                    },
                    splitLine:{show: false},//去除网格线
                },
                yAxis: {
                    type: 'value',
                    scale : true,
                    max : 180,
                    // min : 0,
                    splitNumber: 3,
                },
                grid: {
                    left: '0',
                    bottom: '20%',
                    containLabel: true
                },
                series: [
                    {
                        type: 'line',
                        symbol: 'none',
                        sampling: 'lttb',
                        itemStyle: {
                            color: '#FF9281'
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#FFC1B5'
                                },
                                {
                                    offset: 0.6,
                                    color: '#FFC9C0'
                                },
                                {
                                    offset: 1,
                                    color: '#FFFFFF'
                                }
                            ])
                        },
                        data: data
                    }
                ]
            };
            
            option && myChart.setOption(option);
        },
        // 选择时间段监听
        changeItem(data){
            console.log(data)
        }
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.abilityDetails {
    height: 100%;
    padding: 0 20px;
    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/monthlyTrend3.png') no-repeat;
    background-size: 100%;
    background-color: #f7f7f7;
    .content {
        width: 100%;
        border-radius: 15px;
        background: #fff;
        // height: 80%;
        padding: 0 20px 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .echart {
            width: 100%;
            position: relative;
            .histogram {
                width: 100%;
                height: 240px;
                /deep/ canvas {
                    width: 110% !important;
                    height: 110% !important;
                    left: -2% !important;
                }
            }
            .timenode {
                width: 90%;
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 25px;
                right: 0;
                color: #808080;
            }
        }
        .tipsNum {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 20px;
            .mini,
            .average,
            .max {
                width: 47%;
                padding: 5px 10px;
                border-radius: 5px;
                .title {
                    display: flex;
                    align-items: center;
                    color: #808080;
                    .tipicon {
                        width: 5px;
                        height: 10px;
                        border-radius: 5px;
                        margin-right: 5px;
                    }
                }
                .text {
                    color: #808080;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    .num {
                        color: #282828;
                        font-size: 24px;
                    }
                }
                
            }
            .mini {
                border: 1px solid #9566FF;
                background: #FEF8FE;
                .tipicon {
                    background: #9566FF;
                }
            }
            .max {
                border: 1px solid #FF9180;
                background: #FFF4F2;
                .tipicon {
                    background: #FF9180;
                    
                }
            }
        }
    }
}
</style>